import React from "react";
import Blog from "../../components/Blog";
import H1 from "../../components/h1";
import Par from "../../components/Par";
import SEO from "../../components/seo";

const title = "FREQUENT QUESTIONS AND ANSWERS FOR TELEHEALTH COVID 19";

export default () => (
	<Blog title={title} by="Dr Spiro Raftopoulos">
		<SEO title={title} keywords={[title]} type="article" description={title} />
		<H1>{title}</H1>
		<Par>
			We have the difficult goal of continuing to care for our patients whilst doing everything possible to minimise
			spreading of the coronavirus (COVID-19).
		</Par>
		<h2>Q) I don’t understand Telehealth at all, how does it work?</h2>
		<p>A) Telehealth enables your doctor to assess your medical condition over either telephone of Skype consultation. This can be performed in a location of your choice (e.g. your home). It has typically been used in rural or isolated communities, however in light of the pandemic of the Coronavirus we are now offering this service to all our patients. Our main priority at this stage is to be able to continue to treat patients, whilst working together with them and making sure we look after each other and ease the healthcare burden from GPs and emergency departments.</p>
		<br />
		<h2>Q) Will the clinic still be “open” during this time for phone calls and emails if I have a query or concern about anything?</h2>
		<p>A) Yes, our clinic is <b>currently still physically OPEN</b> and we are still performing face-to-face consults for select case where deemed clinically necessary. Yes our clinic will still be taking phone calls and receiving emails and corresponding back to your referring doctors as per usual. Please feel free to call 9389 1733 or email your enquiries to</p> <a
			href="mailto:info@advancedgiwa.com.au"
			className="text-primary hover:text-primary-dark hover:font-semibold"
		>
			info@advancedgiwa.com.au
							</a>
		<br /><br />
		<h2>Q) Will there be a cost to me for this Telehealth consultation?</h2>
		<p>A) As long as you are an Australian Citizen or Permanent Resident of Australia with a valid Medicare Card, there will be no out of pocket expense to you as your appointment will be fully covered by Medicare.</p>
		<br />
		<h2>Q) Does the Gastroenterologist who would be doing this telehealth consultation typically work in your clinic?</h2>
		<p>A) YES, the gastroenterologist who will be attending this telehealth consultation with you is a gastroenterologist assigned to work in the clinic for Advanced GI in normal circumstances. We encourage you to view our website on staff information to view the profiles of Dr Spiro Raftopoulos, Dr Frank Brennan, Ass. Prof Ian Yusoff, Dr Dev Segarajasingam, Dr Sam Rao, Dr Duy Pham and Dr Claire Gordon. Either one of these gastroenterologist would be consulting you throughout the telehealth consultation. Once the clinic is open again after this pandemic, if it is your choice, you can continue to visit this same gastroenterologist.</p>
		<br />
		<h2>Q) Are you able to consult for all gastroenterological issues/concerns using the TeleHealth system?</h2>
		<p>A) Our gastroenterologists can treat and address an extensive range of gastrointestinal concerns using the telehealth consultations. There will be some issues which are not suitable for Telehealth that may require face to face consultation. In cases where procedures are required, the Telehealth consultation will also serve the purpose of determining if it is safe to wait until the pandemic is over or if a more urgent procedure needs to be arranged with appropriate precautions. </p>
		<br />
		<h2>Q) If at the end of the consultation I needed a script or a pathology request form how will I receive these?</h2>
		<p>A) Our reception staff will fax your script to the chemist of your choice and then send the original to the chemist for you. You then only need to present to your nominated chemist for collection of script(s). If the gastroenterologist requires you to have a blood test, a pathology request will be forwarded to you. However while we are in this pandemic, we would typically advise delay of any non-urgent bloods by 2 – 3 months, if able. This is so you are not unnecessarily exposed in a pathology waiting room (minimising your risk of infection). The gastroenterologist performing your TeleHealth will discuss this with you in the consult; as all cases will vary.</p>
		<br />
		<h2>Q) Will my referring/usual General Practitioner be aware of my telehealth consultation?</h2>
		<p>A) Yes definitely. The consultation will be no different for our gastroenterologists; they still have complete access to our database and software and therefore will be writing up notes as they would if you were in the consulting room with you. They will then be dictating a letter back to your referring or usual General practitioner and informing them of the outcome of your consultation. For non-urgent letters back to your usual doctor there may be a delay in correspondence. For urgent letters, these will be typed immediately and sent to relevant doctors etc.</p>
		<br />
		<h2>Q) What times or days do you have available for this type of consultation?</h2>
		<p>A) Consultations are available Monday to Friday. The day of the week will depend on which Doctor you need to consult with and will be confirmed at the time of your booking.</p>
		<br />
		<h2>Q) If I just need a script can I use this type of consultation for this?</h2>
		<p>A) Yes. We are happy for TeleHealth to be used this way.</p>
		<Par>
			For further information on COVID-19, please see{" "}
			<a href="https://www.healthdirect.gov.au/coronavirus" target="_blank" rel="noopener noreferrer">
				www.healthdirect.gov.au/coronavirus{" "}
			</a>
		</Par>
	</Blog>
);
